/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import MainLayout from "./MainLayout";
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../shared/store/data-Slice";
import { url } from "../../shared/utils/url";
const PassengerHome = React.lazy(() => import("../../pages/passenger/home/Home"));
const StationDetail = React.lazy(() => import("../../pages/passenger/stationDetail/StationDetail"));
const NearByPlaces = React.lazy(() => import("../../pages/passenger/stationDetail/NearByPlaces"));
const Profile = React.lazy(() => import("../../pages/passenger/profile/Profile"));
const KnowStation = React.lazy(() => import("../../pages/passenger/knowStation/KnowStation"));
const Map = React.lazy(() => import("../../pages/passenger/map/Map"));
const Content = React.lazy(() => import("../../pages/passenger/content/Content"));
const Notice = React.lazy(() => import("../../pages/passenger/notice/Notice"));
const ContactUs = React.lazy(() => import("../../pages/common/contactUs/ContactUs"));
const CommingSoon = React.lazy(() => import("../../pages/common/comingSoon/ComingSoon"));
const LostFound = React.lazy(() => import("../../pages/passenger/lostFound/LostFound"));
const Line = React.lazy(() => import("../../pages/passenger/line/Line"));
const Search = React.lazy(() => import("../../pages/passenger/search/Search"));
const Tours = React.lazy(() => import("../../pages/passenger/tours/Tours"));
const WifiService = React.lazy(() => import("../../pages/common/wifiservice/WifiService"));
const ScreenReaderAccess = React.lazy(() => import("../../pages/common/screenReaderAccess/ScreenReaderAccess"));
const SiteMap = React.lazy(() => import("../../pages/common/sitemap/SiteMap"));
const AboutUs = React.lazy(() => import("../../pages/common/aboutUs/AboutUs"));
const FirstLastConnectivity = React.lazy(() => import("../../pages/passenger/stationDetail/FirstLastConnectivity"));
const BookTicket = React.lazy(() => import("../../pages/passenger/bookTicket/BookTicket"));
const TicketDetail = React.lazy(() => import("../../pages/passenger/bookTicket/TicketDetail"));
const NotFound = React.lazy(() => import("../../pages/common/notFound/NotFound"));

const Main = () => {
    const isLogin: boolean = useSelector((state: any) => state.auth.isLogin);
    const dispatch = useDispatch();

    // change live
    useEffect(() => {
        if (url?.env !== "prod") {
            let host: string[] = window.location.hostname.split(".");
            if (host?.length > 0) {
                dispatch(dataActions.setTenant(host?.includes("www") ? host[1] : host[0]))
            }
        }
    }, [window.location.hostname])

    return (
        <>
            <Routes>
                <Route element={<MainLayout />}>
                    {/* passenger */}
                    <Route path="/" element={<PassengerHome />} />
                    <Route path="/:lang" element={<PassengerHome />} />
                    <Route path="/fare-recharge" element={<PassengerHome popup="fare-recharge" />} />
                    <Route path="/:lang/fare-recharge" element={<PassengerHome popup="fare-recharge" />} />
                    <Route path="/feedback" element={<PassengerHome popup="feedback" />} />
                    <Route path="/:lang/feedback" element={<PassengerHome popup="feedback" />} />
                    <Route path="/know-station" element={<KnowStation />} />
                    <Route path="/:lang/know-station" element={<KnowStation />} />
                    <Route path="/station/:code" element={<StationDetail />} />
                    <Route path="/:lang/station/:code" element={<StationDetail />} />
                    <Route path="/nearby-places/:code" element={<NearByPlaces />} />
                    <Route path="/:lang/nearby-places/:code" element={<NearByPlaces />} />
                    <Route path="/nearby-places" element={<NearByPlaces />} />
                    <Route path="/:lang/nearby-places" element={<NearByPlaces />} />
                    <Route path="/first-last-connectivity" element={<FirstLastConnectivity />} />
                    <Route path="/:lang/first-last-connectivity" element={<FirstLastConnectivity />} />
                    <Route path="/line-details" element={<Line />} />
                    <Route path="/:lang/line-details" element={<Line />} />
                    <Route path="/map" element={<Map />} />
                    <Route path="/:lang/map" element={<Map />} />
                    <Route path="/passenger/pages/:slug" element={<Content />} />
                    <Route path="/:lang/passenger/pages/:slug" element={<Content />} />
                    <Route path="/notice" element={<Notice />} />
                    <Route path="/:lang/notice" element={<Notice />} />
                    <Route path="/tours" element={<Tours />} />
                    <Route path="/:lang/tours" element={<Tours />} />
                    <Route path="/search/:search" element={<Search />} />
                    <Route path="/:lang/search/:search" element={<Search />} />
                    <Route path="/contact-us" element={<ContactUs type="passenger" />} />
                    <Route path="/:lang/contact-us" element={<ContactUs type="passenger" />} />
                    <Route path="/lost-found" element={<LostFound />} />
                    <Route path="/:lang/lost-found" element={<LostFound />} />
                    <Route path="/screen-reader-access" element={<ScreenReaderAccess type="passenger" />} />
                    <Route path="/:lang/screen-reader-access" element={<ScreenReaderAccess type="passenger" />} />
                    <Route path="/site-map" element={<SiteMap type="passenger" />} />
                    <Route path="/:lang/site-map" element={<SiteMap type="passenger" />} />
                    <Route path="/about-us" element={<AboutUs type="passenger" />} />
                    <Route path="/:lang/about-us" element={<AboutUs type="passenger" />} />
                    <Route path="/coming-soon" element={<CommingSoon type="passenger" />} />
                    <Route path="/:lang/coming-soon" element={<CommingSoon type="passenger" />} />

                    {/* common-auth */}
                    {isLogin && <>
                        <Route path="/:lang/profile" element={<Profile type="passenger" />} />
                        <Route path="/profile" element={<Profile type="passenger" />} />
                        <Route path="/book-ticket" element={<BookTicket />} />
                        <Route path="/:lang/book-ticket" element={<BookTicket />} />
                        <Route path="/ticket-booked/:id" element={<TicketDetail />} />
                        <Route path="/:lang/ticket-booked/:id" element={<TicketDetail />} />
                        <Route path="/ticket/:id" element={<TicketDetail type="detail" />} />
                        <Route path="/:lang/ticket/:id" element={<TicketDetail type="detail" />} />
                        <Route path="/vigilance-complaints" element={<Profile type="passenger" />} />
                        <Route path="/:lang/vigilance-complaints" element={<Profile type="passenger" />} />
                        <Route path="/grievance-complaints" element={<Profile type="passenger" />} />
                        <Route path="/:lang/grievance-complaints" element={<Profile type="passenger" />} />
                        <Route path="/grievance-complaint/:id" element={<Profile popup="complaint" type="passenger" />} />
                        <Route path="/:lang/grievance-complaint/:id" element={<Profile popup="complaint" type="passenger" />} />
                        <Route path="/vigilance-complaint/:id" element={<Profile popup="complaint" type="passenger" />} />
                        <Route path="/:lang/vigilance-complaint/:id" element={<Profile popup="complaint" type="passenger" />} />
                        <Route path="/complaint/add-remarks/:id" element={<Profile popup="add-remarks" type="passenger" />} />
                        <Route path="/:lang/complaint/add-remarks/:id" element={<Profile popup="add-remarks" type="passenger" />} />
                        <Route path="/tickets" element={<Profile type="passenger" />} />
                        <Route path="/:lang/tickets" element={<Profile type="passenger" />} />
                        <Route path="/transactions" element={<Profile type="passenger" />} />
                        <Route path="/:lang/transactions" element={<Profile type="passenger" />} />
                        <Route path="/lost-item" element={<Profile type="passenger" />} />
                        <Route path="/:lang/lost-item" element={<Profile type="passenger" />} />
                        <Route path="/lost-found/report-lost-item" element={<LostFound popup="report-lost-item" />} />
                        <Route path="/:lang/lost-found/report-lost-item" element={<LostFound popup="report-lost-item" />} />
                        <Route path="/medical-details" element={<Profile type="passenger" />} />
                        <Route path="/:lang/medical-details" element={<Profile type="passenger" />} />
                    </>}
                </Route>
                <Route path="/:lang/404" element={<NotFound />} />
                <Route path="/404" element={<NotFound />} />
                <Route path="*" element={<NotFound />} />
                <Route path="/wifi-service" element={<WifiService />} />
                <Route path="/:lang/wifi-service" element={<WifiService />} />
            </Routes>
        </>
    );
};

export default Main;