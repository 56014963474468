/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { dataActions } from "../../../shared/store/data-Slice";
import { CallSvg, Delete, Edit, GroupClose, LostDetails, MailSvg, PerDetails, SecondaryArr, Warn, CloseBtn, ProfileSuccessIcon } from "../../common/svg/AllSvg";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";
import PrimaryInput from "../../common/form/PrimaryInput";
import SelectSecondaryComponent from "../../common/form/SelectSecondaryComponent";
import { components } from "react-select";
import PrimaryTextarea from "../../common/form/PrimaryTextarea";
import SecondaryBtn from "../../common/form/SecondaryBtn";
import { useDropzone } from "react-dropzone";
import SecondaryBtnLight from "../../common/form/SecondaryBtnLight";
import useAxios from "../../../shared/hooks/use-axios";
import { url } from "../../../shared/utils/url";
import { numberOnPaste } from "../../../shared/utils/numberOnPaste";
import { Bounce, ToastContainer } from "react-toastify";
import { errors } from "../../../shared/utils/errors";

const DropdownIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <SecondaryArr />
      </components.DropdownIndicator>
    )
  );
};

const AddReportItem = () => {
  const { t } = useTranslation('common');
  const reportItemOpen: boolean = useSelector((state: any) => state.data.reportItemOpen);
  const lang: string = useSelector((state: any) => state.ui.lang);
  const user: any = useSelector((state: any) => state.auth.user);
  const [submit, setSubmit] = useState<boolean>(false)
  const [step, setStep] = useState<number>(0)
  const [files, setFiles] = useState<any>([]);
  const [files1, setFiles1] = useState<any>([]);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(false);
  const [err, setErr] = useState<any>("");
  const [subFileErr, setSubFileErr] = useState<boolean>(false);
  const [idProofDocErr, setIdProofDocErr] = useState<string>('');
  const [itemImageErr, setItemImageErr] = useState<string>('');
  const [lostAndFoundCategories, setLostAndFoundCategories] = useState<any>([]);
  const [fromSts, setFromSts] = useState<any[]>([]);
  let [fromStPh, setFromStPh] = useState<any>(t("passenger.home.fare.form_station"));
  const [toSts, setToSts] = useState<any[]>([]);
  let [fromStSearch, setFromStSearch] = useState<any>('');
  let [toStSearch, setToStSearch] = useState<any>('');
  let [toStPh, setToStPh] = useState<any>(t("passenger.home.fare.to_station"));
  const [modalStatus, setModalStatus] = useState<boolean>(false);
  const [submissionId, setSubmissionId] = useState<any>(null);
  const ref: any = useRef();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const steps: any[] = [
    { name: t("passenger.lost_found.report_form.steps.first"), icon: PerDetails },
    { name: t("passenger.lost_found.report_form.steps.sec"), icon: LostDetails },
  ]

  const documentList: any[] = [
    { label: t("passenger.lost_found.report_form.document_list.aadhar_card"), value: "aadhaar_card" },
    { label: t("passenger.lost_found.report_form.document_list.voter_id_card"), value: "voter_id_card" },
    { label: t("passenger.lost_found.report_form.document_list.driving_license"), value: "driving_license" },
    { label: t("passenger.lost_found.report_form.document_list.passport"), value: "passport" },
    { label: t("passenger.lost_found.report_form.document_list.other"), value: "other" }
  ]

  const lostItemWhileList: any[] = [
    { label: t("passenger.lost_found.report_form.lost_item_while_list.traveling_in_metro"), value: "metro" },
    { label: t("passenger.lost_found.report_form.lost_item_while_list.at_st"), value: "station" },
    { label: t("passenger.lost_found.report_form.lost_item_while_list.do_not_know"), value: "do_not_know" }
  ]

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      date: '',
      item_name: '',
      desc: '',
      id_proof_type: '',
      doc_id: '',
      category: '',
      quantity: '',
      lost_item_while: '',
      from_station: '',
      to_station: '',
      metro_station: ''
    },
    validationSchema: step === 0 ? (yup.object().shape({
      id_proof_type: yup.object().required(t("passenger.lost_found.report_form.validation.id_proof_type")),
      doc_id: yup.string().required(t("passenger.lost_found.report_form.validation.doc_id")),
    }))
      : step === 1 ? (yup.object().shape({
        date: yup.date().required(t("passenger.lost_found.report_form.validation.date")),
        item_name: yup.string().required(t("passenger.lost_found.report_form.validation.item_name")),
        desc: yup.string().max(250, t("passenger.lost_found.report_form.validation.desc_len")).required(t("passenger.lost_found.report_form.validation.desc")),
        category: yup.object().required(t("passenger.lost_found.report_form.validation.category")),
        quantity: yup.number().required(t("passenger.lost_found.report_form.validation.quantity")),
        lost_item_while: yup.object().required(t("passenger.lost_found.report_form.validation.lost_item_while")),
        from_station: yup.object().when('lost_item_while', (lostItemWhile) => {
          if (lostItemWhile && lostItemWhile.some(item => item?.value === 'metro')) {
            return yup.object().required(t("passenger.lost_found.report_form.validation.from_station"));
          }
          return yup.object().notRequired();
        }),
        to_station: yup.object().when('lost_item_while', (lostItemWhile) => {
          if (lostItemWhile && lostItemWhile.some(item => item?.value === 'metro')) {
            return yup.object().required(t("passenger.lost_found.report_form.validation.to_station"));
          }
          return yup.object().notRequired();
        }),
        metro_station: yup.object().when('lost_item_while', (lostItemWhile) => {
          if (lostItemWhile && lostItemWhile.some(item => item?.value === 'station')) {
            return yup.object().required(t("passenger.lost_found.report_form.validation.metro_station"));
          }
          return yup.object().notRequired();
        }),
      }))
        : yup.object().shape({}),
    onSubmit: async (values: any) => {
      //submit
      if (step === 0) {

        if (files?.length === 0) {
          setSubFileErr(true)
          setIdProofDocErr("ID Proof is required")
          return;
        }
        setIdProofDocErr('')
        setSubFileErr(false)
        setSubmit(true)
        setStep(1)
        setSubmit(false)
      } else if (step === 1) {
        if (files1?.length === 0) {
          setSubFileErr(true)
          setItemImageErr("Item image is required")
          return;
        }

        try {
          setSubmit(true)
          var formData = new FormData();
          formData.append("id_proof_type", values?.id_proof_type?.value);
          formData.append("document_id", values?.doc_id);
          formData.append("id_proof", files[0]);
          formData.append("item_name", values?.item_name);
          formData.append("category", values?.category?.value);
          formData.append("quantity", values?.quantity);
          formData.append("metro_or_station", values?.lost_item_while?.value);
          values?.metro_station && formData.append("station", values?.metro_station?.id);
          values?.from_station && formData.append("from_station", values?.from_station?.id);
          values?.to_station && formData.append("to_station", values.to_station?.id);
          formData.append("item_lost_date", values?.date);
          formData.append("description", values?.desc);
          formData.append("item_image", files1[0]);

          const res: any = await useAxios.post(`/${(!!lang ? lang : "en") + url.report_lost_items}`, formData);

          if (res && res.status === 201) {
            setModalStatus(true)
            setSubmissionId(res?.data?.submission_id)
          }

        } catch (error) {
          errors(error)
          console.error("error while reporting lost item", error);
          setModalStatus(false)
          setSubmissionId(null)
          setSubmit(false)
        }
      }
    }
  });

  const getFromStations = async () => {
    try {
      let apiUrl: any = ""
      if (!!fromStSearch) {
        apiUrl = await useAxios.get(`/${(!!lang ? lang : "en") + url?.searchStation}${fromStSearch}/`);
      } else {
        apiUrl = await useAxios.get(`/${(!!lang ? lang : "en") + url?.searchStation}all/`);
      }

      if (!!apiUrl) {
        const res: any = apiUrl;
        if (res && res?.status === 200 && res?.data?.length > 0) {
          const arr: any[] = Array.isArray(res?.data) && res?.data?.map((item: any) => ({ label: item?.name, value: item?.code, id: item?.id }));
          setFromSts(arr)

          if (!!formik?.values?.from_station) {
            let obj: any = res?.data?.find((item: any) => formik?.values?.from_station?.value === item?.code)
            formik.setFieldValue("from_station", obj)
          }

          if (!!formik?.values?.metro_station) {
            let obj: any = res?.data?.find((item: any) => formik?.values?.metro_station?.value === item?.code)
            formik.setFieldValue("metro_station", obj)
          }
        }
      }

    } catch (error) {
      console.error("error while feching stations", error);
    }
  }

  const getToStations = async () => {
    try {
      let apiUrl: any = ""
      if (!!toStSearch) {
        apiUrl = await useAxios.get(`/${(!!lang ? lang : "en") + url?.searchStation}${toStSearch}/`);
      } else {
        apiUrl = await useAxios.get(`/${(!!lang ? lang : "en") + url?.searchStation}all/`);
      }

      if (!!apiUrl) {
        const res: any = apiUrl;

        if (res && res?.status === 200 && res?.data?.length > 0) {
          const arr: any[] = Array.isArray(res?.data) && res?.data?.map((item: any) => ({ label: item?.name, value: item?.code, id: item?.id }));
          setToSts(arr)

          if (!!formik?.values?.to_station) {
            let obj: any = res?.data?.find((item: any) => formik?.values?.to_station?.value === item?.code)
            formik.setFieldValue("to_station", obj)
          }
        }
      }
    } catch (error) {
      console.error("error while feching stations", error);
    }
  }

  useEffect(() => {
    !!lang && getFromStations();
  }, [lang, fromStSearch])

  useEffect(() => {
    !!lang && getToStations();
  }, [lang, toStSearch])

  const getLostAndFoundCategories = async () => {
    try {
      const res: any = await useAxios.get(`/${(!!lang ? lang : "en") + url.item_category_list}`);

      if (res && res.status === 200 && res?.data?.results?.length > 0) {
        let lostFoundCategories = []
        for (var i = 0; i < res?.data?.results.length; i++) {
          lostFoundCategories.push({ label: res?.data?.results[i].name, value: res?.data?.results[i].id })
        }
        setLostAndFoundCategories(lostFoundCategories)
        if (!!formik?.values?.category) {
          let obj: any = res?.data?.results?.find((item: any) => formik?.values?.category?.value === item?.id)
          formik.setFieldValue("category", obj)
        }
      }
    } catch (error) {
      console.error("error while fetching lost & found", error);
    }
  }

  useEffect(() => {
    !!lang && getLostAndFoundCategories();
  }, [lang])

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        dispatch(dataActions.setReportItemOpen(false));
        !!params?.lang ? navigate(`/${params?.lang}/lost-found`) : navigate(`/lost-found`)
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  document.onkeydown = function (evt: any) {
    if (evt.keyCode === 27) {
      dispatch(dataActions.setReportItemOpen(false))
      setModalStatus(false)
      !!params?.lang ? navigate(`/${params?.lang}/lost-found`) : navigate(`/lost-found`)
    }
  };

  const simulateChunkUpload = (chunk: any) => {
    // console.log(chunk, "1MB")
    return new Promise((resolve: any, reject: any) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: (acceptedFiles: any) => {
      setErr("")
      if (!acceptedFiles[0]?.name?.match(/\.(jpeg|jpg|png)$/i)) {
        setErr(t("passenger.lost_found.report_form.validation.valid_file"));
        return true;
      }

      if (acceptedFiles[0].size > (5 * 1000 * 1024)) {
        setErr(t("passenger.lost_found.report_form.validation.size_err"));
        return;
      }
      setUploadProgress(0);
      setDisable(true)
      setFiles([acceptedFiles[0]])
      setUploading(true)

      let uploadFile = acceptedFiles[0];
      if (uploadFile) {
        const chunkSize = 1024 * 1024; // 1MB chunk size
        const totalChunks = Math.ceil(uploadFile.size / chunkSize);
        let currentChunk = 0;

        const uploadNextChunk = () => {
          const start = currentChunk * chunkSize;
          const end = Math.min(start + chunkSize, uploadFile.size);
          const chunk = uploadFile.slice(start, end);

          simulateChunkUpload(chunk)
            .then(() => {
              currentChunk++;

              if (currentChunk < totalChunks) {
                const progress = Math.floor((currentChunk / totalChunks) * 100);
                setUploadProgress(progress);

                uploadNextChunk();
              } else {
                setUploadProgress(100);
                setDisable(false)
              }
            })
            .catch((error) => {
              console.error('Error uploading chunk', error);
            });
        };

        uploadNextChunk();
      }
    }
  });

  const removeFile = (idx: number) => {
    let arr: any[] = [];
    for (let i = 0; i < files?.length; i++) {
      const el = files[i];

      if (idx !== i) {
        arr.push(el)
      }
    }

    setFiles(arr)
  };

  const thumbs = files?.map((file: any, idx: number) => (
    <div key={idx} className='w-full flex items-center justify-between px-[15px] xlx:px-[19px] border border-[#D7D8DD] dark:border-[#454545] rounded-full dark:bg-bgDark bg-[#F5F5F5] h-[35px] xlx:h-[40px] gap-[15px]'>
      <Link to={!!file?.url ? file?.url : URL.createObjectURL(file)} download={!!file?.url ? file?.url : URL.createObjectURL(file)} target="_blank" className="text-blackDull dark:text-textBlackDull text-sm leading-snug font-normal truncate hover:underline focus:underline">{file?.name}</Link>

      {(uploadProgress !== 100 && (idx + 1 === files?.length)) ?
        <div className="flex items-center gap-[5px]">
          <h1 className="text-blackDull dark:text-textBlackDull text-sm leading-snug font-normal">{t("passenger.lost_found.report_form.form.uploading")} {uploadProgress}%</h1>
          <button aria-label={t("aria_label.delete")} title={t("aria_label.delete")} className="min-w-[25px] h-[25px] flex items-center justify-center rounded-full hover:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] focus:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] hover:bg-white focus:bg-white border border-transparent focus:border-[#D7D8DD] hover:border-[#D7D8DD] hover:dark:border-[#454545] focus:dark:border-[#454545] dark:hover:bg-blackDull dark:focus:bg-blackDull" onClick={() => removeFile(idx)} type="button">
            <GroupClose />
          </button>
        </div> :
        <div className="flex items-center gap-[5px] whitespace-nowrap">
          <div {...getRootProps()} onClick={(e) => e.stopPropagation}>
            <input {...getInputProps()} />
            <button type="button" onClick={open} className="text-blackDull dark:text-textBlackDull text-sm leading-snug font-semibold disabled:opacity-[0.5] disabled:cursor-not-allowed" disabled={disable}> {t("passenger.lost_found.report_form.form.replace")}</button>
          </div>
          <div className="border-r h-[16px] border-primary pl-[5px]"></div>
          <button aria-label={t("aria_label.delete")} title={t("aria_label.delete")} onClick={() => removeFile(idx)} type="button" className="min-w-[25px] h-[25px] flex items-center justify-center rounded-full hover:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] focus:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] hover:bg-white focus:bg-white border border-transparent focus:border-[#D7D8DD] hover:border-[#D7D8DD] hover:dark:border-[#454545] focus:dark:border-[#454545] dark:hover:bg-blackDull dark:focus:bg-blackDull">
            <Delete />
          </button>
        </div>
      }
    </div>
  ));

  const { getRootProps: getRootProps1, getInputProps: getInputProps1, open: open1 } = useDropzone({
    onDrop: (acceptedFiles: any) => {
      setErr("")
      if (!acceptedFiles[0]?.name?.match(/\.(jpeg|jpg|png)$/i)) {
        setErr(t("passenger.lost_found.report_form.validation.valid_file"));
        return true;
      }

      if (acceptedFiles[0].size > (5 * 1000 * 1024)) {
        setErr(t("passenger.lost_found.report_form.validation.size_err"));
        return;
      }
      setUploadProgress(0);
      setDisable(true)
      setFiles1([acceptedFiles[0]])
      setUploading(true)

      let uploadFile = acceptedFiles[0];
      if (uploadFile) {
        const chunkSize = 1024 * 1024; // 1MB chunk size
        const totalChunks = Math.ceil(uploadFile.size / chunkSize);
        let currentChunk = 0;

        const uploadNextChunk = () => {
          const start = currentChunk * chunkSize;
          const end = Math.min(start + chunkSize, uploadFile.size);
          const chunk = uploadFile.slice(start, end);

          simulateChunkUpload(chunk)
            .then(() => {
              currentChunk++;

              if (currentChunk < totalChunks) {
                const progress = Math.floor((currentChunk / totalChunks) * 100);
                setUploadProgress(progress);

                uploadNextChunk();
              } else {
                setUploadProgress(100);
                setDisable(false)
              }
            })
            .catch((error) => {
              console.error('Error uploading chunk', error);
            });
        };

        uploadNextChunk();
      }
    }
  });

  const removeFile1 = (idx: number) => {
    let arr: any[] = [];
    for (let i = 0; i < files1?.length; i++) {
      const el = files1[i];

      if (idx !== i) {
        arr.push(el)
      }
    }

    setFiles1(arr)
  };

  const thumbs1 = files1?.map((file: any, idx: number) => (
    <div key={idx} className='w-full flex items-center justify-between px-[15px] xlx:px-[19px] border border-[#D7D8DD] dark:border-[#454545] rounded-full dark:bg-bgDark bg-[#F5F5F5] h-[35px] xlx:h-[40px] gap-[15px]'>
      <Link to={!!file?.url ? file?.url : URL.createObjectURL(file)} download={!!file?.url ? file?.url : URL.createObjectURL(file)} target="_blank" className="text-blackDull dark:text-textBlackDull text-sm leading-snug font-normal truncate hover:underline focus:underline">{file?.name}</Link>

      {(uploadProgress !== 100 && (idx + 1 === files1?.length)) ?
        <div className="flex items-center gap-[5px]">
          <h1 className="text-blackDull dark:text-textBlackDull text-sm leading-snug font-normal">{t("passenger.lost_found.report_form.form.uploading")} {uploadProgress}%</h1>
          <button aria-label={t("aria_label.delete")} title={t("aria_label.delete")} className="min-w-[25px] h-[25px] flex items-center justify-center rounded-full hover:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] focus:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] hover:bg-white focus:bg-white border border-transparent focus:border-[#D7D8DD] hover:border-[#D7D8DD] hover:dark:border-[#454545] focus:dark:border-[#454545] dark:hover:bg-blackDull dark:focus:bg-blackDull" onClick={() => removeFile1(idx)} type="button">
            <GroupClose />
          </button>
        </div> :
        <div className="flex items-center gap-[5px] whitespace-nowrap">
          <div {...getRootProps1()} onClick={(e) => e.stopPropagation}>
            <input {...getInputProps1()} />
            <button type="button" onClick={open1} className="text-blackDull dark:text-textBlackDull text-sm leading-snug font-semibold disabled:opacity-[0.5] disabled:cursor-not-allowed" disabled={disable}> {t("passenger.lost_found.report_form.form.replace")}</button>
          </div>
          <div className="border-r h-[16px] border-primary pl-[5px]"></div>
          <button aria-label={t("aria_label.delete")} title={t("aria_label.delete")} onClick={() => removeFile1(idx)} type="button" className="min-w-[25px] h-[25px] flex items-center justify-center rounded-full hover:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] focus:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] hover:bg-white focus:bg-white border border-transparent focus:border-[#D7D8DD] hover:border-[#D7D8DD] hover:dark:border-[#454545] focus:dark:border-[#454545] dark:hover:bg-blackDull dark:focus:bg-blackDull">
            <Delete />
          </button>
        </div>
      }
    </div>
  ));

  useEffect(() => {
    if (!!formik?.values?.id_proof_type) {
      let obj: any = documentList?.find((item: any) => formik?.values?.id_proof_type?.value === item?.value)
      formik.setFieldValue("id_proof_type", obj)
    }

    if (!!formik?.values?.lost_item_while) {
      let obj: any = lostItemWhileList?.find((item: any) => formik?.values?.lost_item_while?.value === item?.value)
      formik.setFieldValue("lost_item_while", obj)
    }
  }, [lang])

  useEffect(() => {
    if (Object?.keys(formik?.errors)?.length === 0) {
      formik.setTouched({});
    }
  }, [formik?.errors, step])

  return (
    <>
      {reportItemOpen &&
        <div className="fixed inset-0 z-[34] top-0 w-full h-full overflow-y-auto bg-[rgba(216,216,216,0.60)] backdrop-blur-[15px]">
          <div className="flex justify-center items-center min-h-screen">
            <div className="m-[15px] mm:mx-auto w-full sm:w-[600px] xlx:w-[740px] h-full relative dark:bg-bgDarkDull bg-white rounded-[10px] shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12),0px_14px_64px_-4px_rgba(24,39,75,0.12)]" ref={ref}>
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
              />

              <div className="w-full flex justify-between gap-[15px] border-b dark:border-[#454545] border-[#DADADA] border-opacity-[0.5] px-[20px] xlx:px-[30px] py-[15px] xlx:py-[20px]">
                <div className="flex flex-col xlx:gap-[2px]">
                  <h3 className="text-primaryText dark:text-white font-semibold">{t("passenger.lost_found.report_form.title")}</h3>
                  <h6 className="text-blackDull dark:text-textBlackDull font-semibold">{t("passenger.lost_found.report_form.desc")}</h6>
                </div>

                <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="rounded-full focus:shadow-[0_0_0_2px_#4e566d5e] hover:shadow-[0_0_0_2px_#4e566d5e] min-w-[23px] h-[23px] flex items-center justify-center bg-[#e4e4e4] dark:bg-[#4c4e5e5e] dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]" type="button" onClick={() => {
                  dispatch(dataActions.setReportItemOpen(false))
                  !!params?.lang ? navigate(`/${params?.lang}/lost-found`) : navigate(`/lost-found`)
                }}>
                  <CloseBtn />
                </button>
              </div>

              <div className="border-b dark:border-[#454545] border-[#DADADA] border-opacity-[0.5] w-full flex items-center justify-center p-[15px] dark:bg-[#dcfcff1a] bg-[#DCFCFF] bg-opacity-[0.5] gap-[15px] xlx:gap-[30px]">
                {Array.isArray(steps) && steps?.map((item: any, index: number) => (
                  <button type="button" className={`${index === step ? "" : "text-opacity-[0.5]"} flex items-center gap-[9px] font-semibold text-[#303030] dark:text-white dark:text-opacity-[0.7] text-sm`} key={index}>
                    <div className={`${step === index ? "bg-white border-[#02C0FC] border" : "bg-[#D9D9D9] dark:bg-[#808080] bg-opacity-[0.7] border-[#D9D9D9] dark:border-[#454545] border border-opacity-[0.7]"} min-w-[30px] h-[30px] rounded-full flex items-center justify-center`}>
                      <item.icon className={`${step === index ? "fill-[#02C0FC]" : "fill-[#1C1B1F] dark:fill-white opacity-[0.7]"}`} />
                    </div>
                    {item?.name}
                  </button>
                ))}
              </div>

              <form onSubmit={(e: any) => { e.preventDefault(); formik?.handleSubmit(); }} className="px-[30px] xlx:px-[60px] py-[28px] flex flex-col gap-[25px] xlx:gap-[30px]">
                {step === 0 && <>
                  <div className="bg-[#FAFAFA] dark:bg-[#5b5b5b99] rounded-[10px] w-full p-[15px] xlx:py-[16px] xlx:px-[32px] flex items-center justify-between gap-[15px]">
                    <div className="flex flex-col gap-[8px]">
                      <h4 className="text-primaryText dark:text-white font-semibold">{`${user?.first_name} ${user?.last_name}`}</h4>

                      <div className="flex flex-col gap-[4px]">
                        <h6 className="flex items-center gap-[8px] text-primary font-semibold"><CallSvg /> {!!user?.phone && `+91 ${user?.phone}`}</h6>
                        {!!user?.email && <h6 className="flex items-center gap-[8px] text-primary font-semibold"><MailSvg /> {user?.email}</h6>}
                      </div>
                    </div>

                    <button type="button" onClick={() => { !!params?.lang ? navigate(`/${params?.lang}/profile`) : navigate(`/profile`); dispatch(dataActions.setReportItemOpen(false)); }} className="bg-primary rounded-[10px] xlx:rounded-[12px] xlx:min-w-[35px] xlx:h-[35px] min-w-[30px] h-[30px] shadow-[0px_1px_4px_0px_rgba(0,0,0,0.25)] flex items-center justify-center focus:shadow-[0_0_0_3px_#059db25e] hover:shadow-[0_0_0_3px_#059db25e]"><Edit className="xlx:w-[18px] xlx:h-[17px] w-[16px] h-[15px] fill-white" /></button>
                  </div>

                  <div className="flex items-center gap-[8px] xlx:gap-[12px] mb-[-10px] xlx:mb-[-15px]">
                    <h6 className="text-primaryText dark:text-white font-semibold whitespace-nowrap">{t("passenger.lost_found.report_form.id_proof")}</h6>
                    <div className="border-b border-[#E6E6F0] w-full"></div>
                  </div>

                  <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-[15px] xlx:gap-[20px]">
                    <div className="flex flex-col gap-[4px]">
                      <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("passenger.lost_found.report_form.form.id_proof_type")}*</h6>
                      <SelectSecondaryComponent
                        options={documentList}
                        name="id_proof_type"
                        placeholder={t("passenger.lost_found.report_form.form.select_id_proof_type")}
                        isSearchable={false}
                        arrow={true}
                        components={{ DropdownIndicator }}
                        onChange={(e: any) => formik.setFieldValue("id_proof_type", e)}
                        onBlur={(e: any) => formik.setFieldTouched("id_proof_type", e)}
                        value={formik.values.id_proof_type}
                        autoFocus={true}
                      />
                      {formik?.touched?.id_proof_type && formik?.errors?.id_proof_type && <div className="flex gap-[6px] items-start"><Warn />
                        <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.id_proof_type}</span>
                      </div>}
                    </div>

                    <div className="flex flex-col gap-[4px] w-full">
                      <PrimaryInput title={`${t("passenger.lost_found.report_form.form.doc_id")}*`} name="doc_id" type="text" placeholder={t("passenger.lost_found.report_form.form.doc_id")} value={formik?.values?.doc_id} onBlur={formik?.handleBlur} onChange={formik?.handleChange} />
                      {formik?.touched?.doc_id && formik?.errors?.doc_id && <div className="flex gap-[6px] items-start"><Warn />
                        <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.doc_id}</span>
                      </div>}
                    </div>
                  </div>

                  <div className="flex flex-col gap-[5px] xlx:gap-[10px] w-full">
                    <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("passenger.lost_found.report_form.form.attach_id_proof")}*</h6>

                    {files?.length === 0 && <div className="flex flex-col gap-[4px]">
                      <div className="h-[40px] w-full rounded-full border border-[#D7D8DD] bg-[#F5F5F5] flex items-center justify-between pl-[5px] pr-[22px] dark:border-[#454545] dark:bg-bgDark">
                        <div {...getRootProps()} onClick={(e) => e.stopPropagation} className={`rounded-full ${(disable || files?.length === 1 || submit) ? "" : "focus:shadow-[0_0_0_3px_#059db25e] hover:shadow-[0_0_0_3px_#059db25e]"}`}>
                          <input {...getInputProps()} />
                          <button type="button" tabIndex={-1} onClick={open} className="h-[30px] flex items-center justify-center w-fit px-[19px] text-white font-bold text-sm !leading-tight rounded-full bg-primary disabled:opacity-[0.5] disabled:cursor-not-allowed" disabled={disable || files?.length === 1 || submit}> {t("passenger.lost_found.report_form.form.upload_file")}</button>
                        </div>

                        <h6 className="text-blackDull dark:text-textBlackDull font-normal !leading-tight">{t("passenger.lost_found.report_form.form.type")}   <span className="text-primaryText font-semibold !leading-tight dark:text-white"> | {t("passenger.lost_found.report_form.form.size")}</span></h6>
                      </div>
                      {!!err && <div className="flex gap-[6px] mt-[4px]"><Warn />
                        <span className="text-danger dark:text-darkDanger text-xs font-medium">{err}</span>
                      </div>}
                      {(!!!err && subFileErr && !!idProofDocErr) && <div className="flex gap-[6px] mt-[4px]"><Warn />
                        <span className="text-danger dark:text-darkDanger text-xs font-medium">{idProofDocErr}</span>
                      </div>}
                    </div>}
                    {files?.length > 0 && <aside className='w-full flex flex-col gap-[12px]'>{thumbs}</aside>}
                  </div>


                  <div className="flex w-full items-end justify-end mt-[60px]">
                    <SecondaryBtn title={t("passenger.lost_found.report_form.form.next")} disabled={submit || disable} onClick={formik.handleSubmit} css="w-fit px-[30px] flex items-center justify-center !rounded-[6px] font-bold uppercase" />
                  </div>
                </>}

                {step === 1 && <>
                  <div className="flex items-center gap-[8px] xlx:gap-[12px] mb-[-10px] xlx:mb-[-15px]">
                    <h6 className="text-primaryText dark:text-white font-semibold whitespace-nowrap">{t("passenger.lost_found.report_form.lost_item_details")}</h6>
                    <div className="border-b border-[#E6E6F0] w-full"></div>
                  </div>

                  <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-[15px] xlx:gap-[20px]">
                    <div className="flex flex-col gap-[4px]">
                      <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("passenger.lost_found.report_form.form.category")}*</h6>
                      <SelectSecondaryComponent
                        options={lostAndFoundCategories}
                        name="category"
                        placeholder={t("passenger.lost_found.report_form.form.select_category")}
                        isSearchable={false}
                        arrow={true}
                        components={{ DropdownIndicator }}
                        onChange={(e: any) => formik.setFieldValue("category", e)}
                        onBlur={(e: any) => formik.setFieldTouched("category", e)}
                        value={formik.values.category}
                      />
                      {formik?.touched?.category && formik?.errors?.category && <div className="flex gap-[6px] items-start"><Warn />
                        <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.category}</span>
                      </div>}
                    </div>

                    <div className="flex flex-col gap-[4px] w-full">
                      <PrimaryInput title={`${t("passenger.lost_found.report_form.form.item_name")}*`} name="item_name" type="text" placeholder={t("passenger.lost_found.report_form.form.enter_item_name")} value={formik?.values?.item_name} onBlur={formik?.handleBlur} onChange={formik?.handleChange} />
                      {formik?.touched?.item_name && formik?.errors?.item_name && <div className="flex gap-[6px] items-start"><Warn />
                        <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.item_name}</span>
                      </div>}
                    </div>
                  </div>

                  <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-[15px] xlx:gap-[20px]">
                    <div className="flex flex-col gap-[4px] w-full">
                      <PrimaryInput title={`${t("passenger.lost_found.report_form.form.quantity")}*`} name="quantity" type="number" placeholder={t("passenger.lost_found.report_form.form.quantity")} value={formik?.values?.quantity} onBlur={formik?.handleBlur} onChange={formik?.handleChange} onFocus={(e: any) => e.target.addEventListener("wheel", function (e: any) { e.preventDefault() }, { passive: false })}
                        onKeyDown={(e: any) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()} onPaste={(e: any) => numberOnPaste(e)} />
                      {formik?.touched?.quantity && formik?.errors?.quantity && <div className="flex gap-[6px] items-start"><Warn />
                        <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.quantity}</span>
                      </div>}
                    </div>

                    <div className="flex flex-col gap-[4px]">
                      <PrimaryInput title={`${t("passenger.lost_found.report_form.form.date")}*`} name="date" type="date" placeholder={t("passenger.lost_found.report_form.form.date")} value={formik?.values?.date} onBlur={formik?.handleBlur} onChange={formik?.handleChange} max={new Date().toISOString().split("T")[0]} />
                      {formik?.touched?.date && formik?.errors?.date && <div className="flex gap-[6px] items-start"><Warn />
                        <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.date}</span>
                      </div>}
                    </div>
                  </div>

                  <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-[15px] xlx:gap-[20px]">
                    <div className="flex flex-col gap-[4px]">
                      <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("passenger.lost_found.report_form.form.lost_item_while")}*</h6>
                      <SelectSecondaryComponent
                        options={lostItemWhileList}
                        name="lost_item_while"
                        placeholder={t("passenger.lost_found.report_form.form.select_lost_item_while")}
                        isSearchable={false}
                        arrow={true}
                        components={{ DropdownIndicator }}
                        onChange={(e: any) => formik.setFieldValue("lost_item_while", e)}
                        onBlur={(e: any) => formik.setFieldTouched("lost_item_while", e)}
                        value={formik.values.lost_item_while}
                      />
                      {formik?.touched?.lost_item_while && formik?.errors?.lost_item_while && <div className="flex gap-[6px] items-start"><Warn />
                        <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.lost_item_while}</span>
                      </div>}
                    </div>
                  </div>

                  {formik?.values?.lost_item_while?.value === 'metro' ?
                    <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-[15px] xlx:gap-[20px]">
                      <div className="flex flex-col gap-[4px]">
                        <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("passenger.lost_found.report_form.from_station")}*</h6>
                        <SelectSecondaryComponent
                          options={fromSts.filter(item => item?.value !== formik.values.to_station?.value)}
                          name="from_station"
                          placeholder={fromStSearch || fromStPh}
                          isSearchable={true}
                          arrow={true}
                          components={{ DropdownIndicator }}
                          onChange={(e: any) => formik.setFieldValue("from_station", e)}
                          onBlur={(e: any) => formik.setFieldTouched("from_station", e)}
                          value={formik.values.from_station}
                        />
                        {formik?.touched?.from_station && formik?.errors?.from_station && <div className="flex gap-[6px] items-start"><Warn />
                          <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.from_station}</span>
                        </div>}
                      </div>
                      <div className="flex flex-col gap-[4px]">
                        <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("passenger.lost_found.report_form.to_station")}*</h6>
                        <SelectSecondaryComponent
                          options={toSts.filter(item => item?.value !== formik.values.from_station?.value)}
                          name="to_station"
                          placeholder={toStSearch || toStPh}
                          isSearchable={true}
                          arrow={true}
                          components={{ DropdownIndicator }}
                          onChange={(e: any) => formik.setFieldValue("to_station", e)}
                          onBlur={(e: any) => formik.setFieldTouched("to_station", e)}
                          value={formik.values.to_station}
                        />
                        {formik?.touched?.to_station && formik?.errors?.to_station && <div className="flex gap-[6px] items-start"><Warn />
                          <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.to_station}</span>
                        </div>}
                      </div>
                    </div>
                    : formik?.values?.lost_item_while?.value === 'station' ?
                      <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-[15px] xlx:gap-[20px]">
                        <div className="flex flex-col gap-[4px]">
                          <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("passenger.lost_found.report_form.metro_station")}*</h6>
                          <SelectSecondaryComponent
                            options={fromSts}
                            name="metro_station"
                            placeholder={fromStSearch || fromStPh}
                            isSearchable={true}
                            arrow={true}
                            components={{ DropdownIndicator }}
                            onChange={(e: any) => formik.setFieldValue("metro_station", e)}
                            onBlur={(e: any) => formik.setFieldTouched("metro_station", e)}
                            value={formik.values.metro_station}
                          />
                          {formik?.touched?.metro_station && formik?.errors?.metro_station && <div className="flex gap-[6px] items-start"><Warn />
                            <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.metro_station}</span>
                          </div>}
                        </div>
                      </div>
                      :
                      ''
                  }

                  <div className="flex flex-col gap-[4px]">
                    <PrimaryTextarea title={`${t("passenger.lost_found.report_form.form.desc")}*`} placeholder={t("passenger.lost_found.report_form.form.enter_desc")} name="desc" rows="4" value={formik?.values?.desc} onBlur={formik?.handleBlur} onChange={formik?.handleChange} />
                    {formik?.touched?.desc && formik?.errors?.desc && <div className="flex gap-[6px] items-start"><Warn />
                      <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.desc}</span>
                    </div>}
                  </div>

                  <div className="flex flex-col gap-[4px]">
                    <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("passenger.lost_found.report_form.form.attach_item_img")}*</h6>

                    {files1?.length === 0 && <div className="flex flex-col gap-[4px]">
                      <div className="h-[35px] xlx:h-[40px] w-full rounded-full border border-[#D7D8DD] bg-[#F5F5F5] flex items-center justify-between pl-[5px] pr-[18px] xlx:pr-[22px] dark:border-[#454545] dark:bg-bgDark">
                        <div {...getRootProps1()} onClick={(e) => e.stopPropagation} className={`rounded-full ${(disable || files1?.length === 1 || submit) ? "" : "focus:shadow-[0_0_0_3px_#059db25e] hover:shadow-[0_0_0_3px_#059db25e]"}`}>
                          <input {...getInputProps1()} />
                          <button type="button" tabIndex={-1} onClick={open1} className="h-[25px] xlx:h-[30px] flex items-center justify-center w-fit px-[15px] xlx:px-[19px] text-white font-bold text-sm leading-snug rounded-full bg-primary disabled:opacity-[0.5] disabled:cursor-not-allowed" disabled={disable || files1?.length === 1 || submit}> {t("passenger.lost_found.report_form.form.upload_file")}</button>
                        </div>

                        <h6 className="text-blackDull dark:text-textBlackDull font-normal">{t("passenger.lost_found.report_form.form.type")}   <span className="text-primaryText dark:text-white font-semibold"> | {t("passenger.lost_found.report_form.form.size")}</span></h6>
                      </div>
                      {!!err && <div className="flex gap-[6px] mt-[4px]"><Warn />
                        <span className="text-danger dark:text-darkDanger text-xs font-medium">{err}</span>
                      </div>}
                      {(!!!err && subFileErr && !!itemImageErr) && <div className="flex gap-[6px] mt-[4px]"><Warn />
                        <span className="text-danger dark:text-darkDanger text-xs font-medium">{itemImageErr}</span>
                      </div>}
                    </div>}
                    {files1?.length > 0 && <aside className='w-full flex flex-col gap-[12px]'>{thumbs1}</aside>}
                  </div>


                  <div className="flex w-full items-end justify-between">
                    <SecondaryBtnLight type="button" title={t("passenger.lost_found.report_form.form.back")} css="px-[30px] xlx:px-[40px] font-semibold rounded-[6px] text-sm leading-snug" onClick={() => setStep(0)} />
                    <SecondaryBtn title={t("contact_us.form.submit")} disabled={submit || disable} onClick={formik.handleSubmit} css="w-fit px-[30px] flex items-center justify-center !rounded-[6px] font-bold uppercase" />
                  </div>
                </>}

              </form>
            </div>
          </div>
        </div >}

      {/* Success Modal */}
      {modalStatus &&
        <div className="fixed inset-0 z-[34] overflow-y-hidden top-0 ">
          <div
            className="fixed inset-0 w-full h-full bg-[rgba(171,171,171,0.70)] backdrop-blur-[10px]" onClick={() => {
              dispatch(dataActions.setReportItemOpen(false))
              setModalStatus(false)
              !!params?.lang ? navigate(`/${params?.lang}/lost-found`) : navigate(`/lost-found`)
            }}
          ></div>
          <div className="flex justify-center items-center min-h-screen">
            <div className="relative m-[15px] mm:mx-auto dark:bg-bgDarkDull bg-white rounded-[10px] w-full md:w-[600px] xlx:w-[713px] h-[450px] xlx:h-[528px] shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12),0px_14px_64px_-4px_rgba(24,39,75,0.12))]">
              <div className="absolute top-[18px] right-[22px]">
                <button aria-label={t("aria_label.close")} title={t("aria_label.close")} type="button" className="rounded-full focus:shadow-[0_0_0_2px_#4e566d5e] hover:shadow-[0_0_0_2px_#4e566d5e] min-w-[23px] h-[23px] flex items-center justify-center bg-[#e4e4e4] dark:bg-[#4c4e5e5e] dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]" onClick={() => {
                  dispatch(dataActions.setReportItemOpen(false))
                  setModalStatus(false)
                  !!params?.lang ? navigate(`/${params?.lang}/lost-found`) : navigate(`/lost-found`)
                }}>
                  <CloseBtn />
                </button>
              </div>

              <div className="w-full h-full flex flex-col items-center justify-center px-[22px]">
                <ProfileSuccessIcon />
                <h3 className="text-primaryText dark:text-white font-bold mt-[20px] xlx:mt-[30px] text-center">{t("passenger.lost_found.report_form.success.title")}</h3>
                <h5 className="uppercase text-primary font-bold mt-[5px] xlx:mt-[10px] text-center">{t("passenger.lost_found.report_form.success.reference_no")} - {submissionId}</h5>
                <h5 className="text-blackDull dark:text-textBlackDull font-medium text-center mt-[15px] xlx:mt-[20px]">{t("passenger.lost_found.report_form.success.thanks")}</h5>

                <div className="flex items-center gap-[10px] mt-[20px] xlx:mt-[43px]">
                  <button type="button" className="border border-primary xlx:h-[40px] h-[35px] flex items-center justify-center rounded-[6px] px-[20px] xlx:px-[29px] w-fit text-primary font-extrabold uppercase text-sm leading-snug focus:shadow-[0_0_0_3px_#059db25e] hover:shadow-[0_0_0_3px_#059db25e]" onClick={() => {
                    dispatch(dataActions.setReportItemOpen(false))
                    setModalStatus(false)
                    !!params?.lang ? navigate(`/${params?.lang}/lost-found`) : navigate(`/lost-found`)
                  }}>{t("passenger.lost_found.report_form.success.button")}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default AddReportItem;